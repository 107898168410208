import { useContext, useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react'
import { home, calendar, person, ellipsisVerticalOutline, people } from 'ionicons/icons'
import { AuthContext } from './auth'
import { useHistory } from 'react-router-dom'
import More from './More'
import Employees from './employees'
import EmployeeDetails from './employees/details'
import Location from './locations'
import LocationDetails from './locations/details'

import Dashboard from './dashboard_v2'
import SubscriptionPackages from './subscriptionPackages'
import SubscriptionPackagesDetails from './subscriptionPackages/details'
import CorporateDeals from './corporateDeals'
import CorporateDealsDetails from './corporateDeals/details'
import Service from './services'
import ServiceDetails from './services/details'
import Product from './product'
import ProductDetails from './product/details'
import Customer from './customers'
import CustomerDetails from './customers/details'
import Appointment from './appointments'
import AppointmentDetails from './appointments/details'
import Users from './users'
import UserDetails from './users/details'
import InviteDetails from './users/inviteDetails'
import Subscriptions from './subscriptions'
import SubscriptionDetails from './subscriptions/details'
import Reports from './reports'
import RequestReviewHomePage from './RequestCustomerReviews'
import EmailRemarketing from './EmailRemarketing'
import EmailRemarketingDetails from './EmailRemarketing/DetailView'
import AddMarketingRule from './EmailRemarketing/AddMarketingRule'
import EmailConfiguration from './ConfigureEmailSettings/EmailConfiguration'
import CancelSubscription from './subscriptions/CancelSubscription'
import CustomerAcquisition from './CustomerAcquisition'
import AcquisitionSettings from './CustomerAcquisition/AcquisitionSettings'
import AddOrUpdateAcquisitionSoure from './CustomerAcquisition/AddOrUpdateAcquisitionSoure'
import ReportDetailPage from './reports/detail'
import NotificationReport from './reports/NotificationReport'
import MembershipsLineChart from './reports/MembershipsLineChart'
import AppointmentsHeatmap from './reports/appointmentHeatMap'
import AppointmentsScheduledHeatmap from './reports/scheduledHeatMap'
import CancelledSubscriptionDetails from './subscriptions/CancelledSubscriptions/Details'
import CustomerCreditBalance from './customers/CreditBalance'
import CustomerHistory from './customers/CustomerHistory'
import CustomerPaymentHistory from './customers/CustomerPaymentHistory'
import CustomerNotifications from './customers/CustomerNotifications'
import Discount from './discounts'
import DiscountDetails from './discounts/details'
import TimeGraph from './employees/Graphs/TimeGraph'
import EmailReminder from './EmailReminders'
import AddNewUser from './users/AddNewUser'
import AccountsReport from './reports/AccountsReport'
import CustomerGallery from './commonComponents/Gallery'
import BusinessDetails from './BusinessSection/Details'
import CreateSubscription from './subscriptionPackages/CreateSubscription'
import ImpressionsByRefId from './reports/ImpressionsByRefId'
import CustomerReviews from './CustomerReviews'
import FilledCapacity from './reports/FilledCapacity'
import Deflectionsettings from './BadReviewDeflection/settings'
import RetentionReport from './reports/RetentionReport'
import CheckoutList from './Checkout/listView'
import MessagesListView from './Messages/ListView'
import ConversationDetails from './Messages/ConversationDetails'
import BusinessDomains from './BusinessSection/Domains'
import StatsDetails from './dashboard_v2/statsDetail'
import PhoneAssistant from './AITools/PhoneAssistant'
import ChatBot from './AITools/ChatBot'
import CashManagement from './CashManagement'
import CashManagementDetails from './CashManagement/AccountDetails'
import AccountDetails from './CashManagement/AccountDetails'
import EmployeeSchedule from './employees/schedulePlanner'
// import ImpressionsByRefId from './reports/ImpressionsByRefId'

const styles = {
  icon: {
    height: 22,
    width: 22,
  },
  tabButton: {
    backgroundColor: 'transparent',
  },
}

const Tabs = () => {
  const { userData, firebaseUser, businessData, getUserData, businessDataError } = useContext(AuthContext)
  const history = useHistory()
  useEffect(() => {
    getUserData()
  }, [])

  if (!firebaseUser) {
    return <Redirect to='/login' />
  }

  if (firebaseUser && userData && !businessData && !businessDataError) {
    return <Redirect noThrow to='/setup' />
  }

  if (businessDataError) {
    return <Redirect noThrow to='/' />
  }

  return (
    <>
      <IonTabs>
        <IonRouterOutlet>
          {/* <Route path='/core/packagesList' render={props => <PackagesListView {...props} />} /> */}
          {/* <Route exact path="/home" render={(props) => (<Home {...props} openLoginModal={openLoginModal} openRegisterModal={openRegisterModal} openScheduleModal={openScheduleModal} appointments={appointments} />)} /> */}
          <Route exact path='/core/dashboard' render={(props) => <Dashboard {...props} />} />
          <Route exact path='/core/employees' render={(props) => <Employees {...props} />} />
          <Route path='/core/employees/:id' render={(props) => <EmployeeDetails {...props} />} />
          <Route path='/core/employee/schedule' render={(props) => <EmployeeSchedule {...props} />} />
          <Route exact path='/core/locations' render={(props) => <Location {...props} />} />
          <Route path='/core/locations/:id' render={(props) => <LocationDetails {...props} />} />
          <Route exact path='/core/subscriptions'>
            <Subscriptions />
          </Route>
          <Route exact path='/core/subscription/create'>
            <CreateSubscription />
          </Route>
          <Route path='/core/subscriptions/:id' render={(props) => <SubscriptionDetails {...props} />} />
          <Route path='/core/cancelledSubscription/:id' render={(props) => <SubscriptionDetails {...props} />} />
          <Route path='/core/creditBalance/:id' render={(props) => <CustomerCreditBalance {...props} />} />
          <Route exact path='/core/subscriptionPackages'>
            {' '}
            <SubscriptionPackages />{' '}
          </Route>
          <Route path='/core/subscriptionPackages/:id' render={(props) => <SubscriptionPackagesDetails {...props} />} />
          <Route exact path='/core/corporateDeals'>
            <CorporateDeals />
          </Route>
          <Route path='/core/corporateDeals/:id' render={(props) => <CorporateDealsDetails {...props} />} />
          <Route exact path='/core/services'>
            <Service />
          </Route>
          <Route path='/core/services/:id' render={(props) => <ServiceDetails {...props} />} />
          <Route exact path='/core/products'>
            <Product />
          </Route>
          <Route path='/core/products/:id' render={(props) => <ProductDetails {...props} />} />
          <Route exact path='/core/discounts'>
            <Discount />
          </Route>
          <Route exact path='/core/checkout'>
            <CheckoutList />
          </Route>
          <Route exact path='/core/cashManagement'>
            <CashManagement />
          </Route>
          <Route path='/core/cashManagement/:id' component={AccountDetails} exact={true} />
          <Route path='/core/discounts/:id' render={(props) => <DiscountDetails {...props} />} />
          <Route exact path='/core/customers'>
            <Customer />
          </Route>
          <Route path='/core/customers/:id' render={(props) => <CustomerDetails {...props} />} />
          <Route path='/core/history/:id' render={(props) => <CustomerHistory {...props} />} />
          <Route path='/core/paymentHistory/:id' render={(props) => <CustomerPaymentHistory {...props} />} />
          <Route path='/core/manageNotifications/:id' render={(props) => <CustomerNotifications {...props} />} />
          <Route path='/core/appointmentgallery/:id' render={(props) => <CustomerGallery type={'appointment'} {...props} />} />
          <Route path='/core/gallery/:id' render={(props) => <CustomerGallery {...props} />} />
          <Route exact path='/core/appointments' render={(props) => <Appointment />} />
          <Route path='/core/appointments/:id' render={(props) => <AppointmentDetails {...props} />} />
          <Route path='/core/messages/:id' render={(props) => <ConversationDetails {...props} />} />
          <Route exact path='/core/reports' render={(props) => <Reports />} />
          <Route path='/core/report/:id' render={(props) => <ReportDetailPage {...props} />} />
          <Route path='/core/impressions/:id' render={(props) => <ImpressionsByRefId {...props} />} />

          <Route exact path='/core/users' render={(props) => <Users />} />
          <Route exact path='/core/messages' render={(props) => <MessagesListView />} />
          <Route path='/core/users/user/:id' render={(props) => <UserDetails {...props} />} />
          <Route path='/core/users/addNew' render={(props) => <AddNewUser />} />
          {/* AI Tools Routes */}
          <Route exact path='/core/AIPhoneAssistant' render={(props) => <PhoneAssistant />} />
          <Route exact path='/core/AIChatBot' render={(props) => <ChatBot />} />

          <Route path='/core/users/invites/:id' render={(props) => <InviteDetails {...props} />} />
          <Route exact path='/core/more'>
            <More />
          </Route>
          <Route exact path='/core' render={(props) => <Dashboard {...props} />}>
            <Redirect to='/core/dashboard' />
          </Route>
          <Route exact path='/'>
            <Redirect to='/core/dashboard' />
          </Route>
          <Route exact path='/core/customerReviews'>
            <CustomerReviews />
          </Route>
          <Route exact path='/core/requestCustomerReviews'>
            <RequestReviewHomePage />
          </Route>
          <Route exact path='/core/emailRemarketing'>
            <EmailRemarketing />
          </Route>
          <Route exact path='/core/customerAcquisition'>
            <CustomerAcquisition />
          </Route>
          <Route exact path='/core/emailReminders'>
            <EmailReminder />
          </Route>
          <Route exact path='/core/filledCapacity'>
            <FilledCapacity />
          </Route>
          <Route exact path='/core/acquisitionSettings'>
            <AcquisitionSettings />
          </Route>
          <Route path='/core/acquisitionReference/:id' render={(props) => <AddOrUpdateAcquisitionSoure {...props} />} />
          <Route exact path='/core/acquisitionReference'>
            <AddOrUpdateAcquisitionSoure />
          </Route>
          <Route exact path='/core/cancelSubscription/:id' render={(props) => <CancelSubscription {...props} />} />
          <Route exact path='/core/addEmailRemarketingRule' render={(props) => <AddMarketingRule {...props} />} />
          <Route exact path='/core/emailConfiguration/:id' render={(props) => <EmailConfiguration {...props} />} />
          <Route exact path='/core/emailConfiguration'>
            <EmailConfiguration />
          </Route>
          <Route path='/core/emailRemarketing/:id' render={(props) => <EmailRemarketingDetails {...props} />} />

          <Route exact path='/core/notificationReport'>
            <NotificationReport />
          </Route>
          <Route exact path='/core/accountsReport'>
            <AccountsReport />
          </Route>
          <Route exact path='/core/retentionReport'>
            <RetentionReport />
          </Route>

          <Route exact path='/core/deflectionSettings'>
            <Deflectionsettings />
          </Route>

          <Route exact path='/core/membershipCancellations'>
            <MembershipsLineChart />
          </Route>
          <Route exact path='/core/customersByDay'>
            <AppointmentsHeatmap businessId={businessData?._id} />
          </Route>
          <Route exact path='/core/customersSchedule'>
            <AppointmentsScheduledHeatmap businessId={businessData?._id} />
          </Route>
          <Route path='/core/serviceTime/:id' render={(props) => <TimeGraph type='serviceTimeStats' title={'Service Time'} {...props} />} />

          <Route exact path='/core/stats'>
            <StatsDetails />
          </Route>
        </IonRouterOutlet>

        <IonTabBar slot='bottom' style={{ backgroundColor: 'white' }}>
          <IonTabButton tab='dashboard' href='/core/dashboard' style={styles.tabButton} routerDirection='none'>
            <IonIcon icon={home} style={styles.icon} />
            <IonLabel>Dashboard</IonLabel>
          </IonTabButton>
          <IonTabButton tab='customer' href='/core/customers' style={styles.tabButton} routerDirection='none'>
            <IonIcon icon={people} style={styles.icon} />
            <IonLabel>Customers</IonLabel>
          </IonTabButton>
          <IonTabButton tab='employees' href='/core/employees' style={styles.tabButton} routerDirection='none'>
            <IonIcon icon={person} style={styles.icon} />
            <IonLabel>Employees</IonLabel>
          </IonTabButton>
          <IonTabButton tab='appointments' href='/core/appointments' style={styles.tabButton} routerDirection='none'>
            <IonIcon icon={calendar} style={styles.icon} />
            <IonLabel>Appointments</IonLabel>
          </IonTabButton>
          <IonTabButton tab='more' href='/core/more' style={styles.tabButton} routerDirection='none'>
            <IonIcon icon={ellipsisVerticalOutline} style={styles.icon} />
            <IonLabel>More</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      {/* <LoginModal authModalData={authModalData} setAuthModalData={setAuthModalData} /> */}
      {/* <ScheduleModal scheduleModalData={scheduleModalData} setScheduleModalData={setScheduleModalData} getAppointments={getAppointments} /> */}
    </>
  )
}

export default Tabs
